import "./orderList.css";
import Table from "../../components/advancedTable";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    VerifiedUser,
    LocalShipping,
    Edit,
    AddAlert,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOrders, voidOrder, acceptOrder } from "../../redux/apiCalls";
import moment from "moment";

import { publicRequest } from "../../requestMethods";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";
import createDeliveryOrderForm from "../../components/pdf/createDeliveryOrderForm";
import { useHistory } from "react-router";
import createBulk from "../../components/pdf/createBulk";
import { userRequest } from "../../requestMethods";
import {clearCart, editOrderSeedData} from "../../redux/cartRedux";

const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];

const limitOptions = [
    {
        label: "Basic",
        value: 20,
    },
    {
        label: "Export",
        value: 9999,
    },
];

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        createdBy: manager
            ? principle
            : staff
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: undefined,
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        user: location
            ? location.state
                ? location.state.outlet
                : undefined
            : undefined,
    });

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const [statusMenu, setStatusMenu] = useState(false);
    const [ordersData, setOrdersData] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [vehiclesData, setVehiclesData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [payloadpaymentType, setPayloadPaymentType] = useState("cash");
    const [accountName, setAccountName] = useState("pbb");
    const [installmentMenu, setInstallmentMenu] = useState(false);
    const [journalMenu, setJournalMenu] = useState(false);
    const [remainingBalance, setRemainingBalance] = useState([]);
    const [amount, setAmount] = useState(0);
    const [card, setCard] = useState(undefined);
    const [invoice, setInvoice] = useState(undefined);
    const [date, setDate] = useState(undefined);

    // eslint-disable-next-line
    const [paymentType, setPaymentType] = useState([
        {
            label: "Cash",
            value: "cash",
        },
        { value: "onlineTransfer", label: "Online Transfer" },
        { value: "tng", label: "Touch N Go" },
        { value: "cheque", label: "Cheque" },
        { value: "card", label: "Debit Card" },
        { value: "creditCard", label: "Credit Card" },
        { value: "ziip6", label: "ZIIP - 6 months" },
        { value: "ziip12", label: "ZIIP - 12 months" },
        { value: "ziip18", label: "ZIIP - 18 months" },
        { value: "ziip24", label: "ZIIP - 24 months" },
        { value: "others", label: "Others" },
    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getOrders(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
    }, [dispatch, inputs, isLoaded]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    useEffect(() => {
        if (installmentMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [installmentMenu]);

    useEffect(() => {
        if (journalMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [journalMenu]);

    const handleChange = (e) => {
        setPayloadPaymentType(e.target.value);
    };

    const handleAccountType = (e) => {
        setAccountName(e.target.value);
    };

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
    };

    const handleCard = (e) => {
        setCard(e.target.value);
    };

    const handleDate = (e) => {
        setDate(e.target.value);
    };

    const handleInstallment = async (id) => {
        if (currentUser) {
            try {
                const res = await userRequest.post("/installment", {
                    order: id,
                    paymentType: payloadpaymentType,
                    amount: amount,
                    card: card,
                    createdAt: moment
                        .utc(date)
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                });

                if (res.data) {
                    alert("Installment created successfully.");
                    setInstallmentMenu(false);
                    window.location.reload();
                } else {
                    alert("Server issue, please contact support");
                    setInstallmentMenu(false);
                }
            } catch (err) {
                alert(
                    "Please make sure remaining balance is less than amount, and use full payment for full payment."
                );
                setInstallmentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setInstallmentMenu(false);
        }
    };

    const handleJournal = async (id) => {
        if (currentUser) {
            try {
                const res = await userRequest.post("/journal", {
                    order: id,
                    paymentType: accountName,
                    amount: amount,
                    card: card,
                });

                if (res.data) {
                    alert("Installment created successfully.");
                    setInstallmentMenu(false);
                    window.location.reload();
                } else {
                    alert("Server issue, please contact support");
                    setInstallmentMenu(false);
                }
            } catch (err) {
                alert(
                    "Please make sure remaining balance is less than amount, and use full payment for full payment."
                );
                setInstallmentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setInstallmentMenu(false);
        }
    };

    const handlePaymentType = (e) => {
        setPayloadPaymentType(e.value);
    };

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleAccept = (id) => {
        acceptOrder(id, payloadpaymentType, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleInputUser = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const handleInputVehicle = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, plate: e.label };
        });
    };

    

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const handleLimitChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const handleInvoiceChange = (e) => {
        setInputs((prev) => {
            return { ...prev, invoice: e };
        });
        setIsLoaded(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleInvoiceChange(invoice);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [invoice]);

    const handleDelayedInvoiceChange = (e) => {
        setInvoice(e.target.value);
    };

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData, dispatch]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        if (vehiclesData[0] === undefined) {
            getVehicles();
        }
    }, [vehiclesData, dispatch]);

    const getVehicles = async () => {
        const res = await publicRequest.get("/vehicles/all", {
            params: {},
        });
        setVehiclesData(res.data);
    };

    const productColumns = [
        {
            accessor: "code",
            Header: "Name",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "quantity",
            Header: "Quantity",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "price",
            Header: "Unit Price",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">
                    RM {value ? formatter.format(value) : "0"}
                </div>
            ),
        },
        {
            accessor: "amount",
            Header: "Total Price",
            sortable: false,
            Cell: (params) => (
                <div className="initialPadding">
                    RM{" "}
                    {params.row.original.amount
                        ? formatter.format(
                              params.row.original.amount *
                                  params.row.original.price
                          )
                        : "0"}
                </div>
            ),
        },
    ];

    const columns = [
        {
            accessor: "action",
            Header: "Action",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        {params.row.original.status === "pending" && (
                            <VerifiedUser
                                className=" pointer icon mr-2"
                                onClick={() => {
                                    const original = params.row.original;
                                    const remaining =
                                        original.status === "pending"
                                            ? original.amount -
                                              (original.deposit
                                                  ? original.deposit
                                                  : 0) -
                                              original.discount
                                            : 0;
                                    setModalData(original._id);
                                    setRemainingBalance(remaining);
                                    setInstallmentMenu(true);
                                }}
                            />
                        )}
                        <CloudDownload
                            className="pointer icon mr-2"
                            onClick={() => {
                                createCustomerInvoice(params.row.original);
                            }}
                        />
                        <LocalShipping
                            className="pointer icon mr-2"
                            onClick={() => {
                                createDeliveryOrderForm(params.row.original);
                            }}
                        />
                        {params.row.original.status === "completed" && (
                            <AddAlert
                                className=" pointer icon mr-2"
                                onClick={() => {
                                    const original = params.row.original;
                                    setModalData(original._id);
                                    setJournalMenu(true);
                                }}
                            />
                        )}
                    </>
                );
            },
        },
        {
            accessor: "createdAt",
            Header: "Purchase Date",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "product",
            Header: "Products",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon mr-2"
                            onClick={() => {
                                setModalData(params.row.original.products);
                                setStatusMenu(true);
                            }}
                        />
                        <Edit
                            className="pointer icon"
                            onClick={() => {
                                dispatch(clearCart());
                                dispatch(
                                    editOrderSeedData(params.row.original)
                                );
                                history.push(
                                    `/order/${params.row.original._id}`,
                                    {
                                        order: params.row.original,
                                    }
                                );
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "invoiceId",
            Header: "Invoice",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "plate",
            Header: "Customer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            accessor: "discount",
            Header: "Discount",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">
                    RM {value ? formatter.format(value) : "0"}
                </div>
            ),
        },
        {
            accessor: "products",
            Header: "Total",
            sortable: false,
            Cell: (params) => {
                let total = 0;
                if (params.row.original.products !== undefined) {
                    for (const product of params.row.original.products) {
                        total = total + product.price * product.amount;
                    }
                }
                return (
                    <div className="capitalize">
                        {params
                            ? params.row.original.country === "singapore"
                                ? "$"
                                : "RM"
                            : "RM"}
                        {formatter.format(
                            params.row.original.deposit &&
                                params.row.original.status === "deposit"
                                ? params.row.original.deposit
                                : params.row.original.deposit &&
                                  params.row.original.status !== "deposit"
                                ? total - params.row.original.discount
                                : total - params.row.original.discount
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "paymentType",
            Header: "Payment Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
    ];

    return (
        <>
            {!isLoaded ? (
                <></>
            ) : (
                <>
                    <div className="productList">
                        <div className="samerow">
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="dateFrom"
                                    value={inputs.dateFrom}
                                    type="date"
                                    onChange={(e) => handleDateFrom(e)}
                                />
                            </div>
                            <div className="addProductItem ">
                                <input
                                    className="inputFilter"
                                    name="dateTo"
                                    value={inputs.dateTo}
                                    type="date"
                                    onChange={(e) => handleDateTo(e)}
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                <Select
                                    options={usersData.map((status, i) => {
                                        return {
                                            label: `${status.name}-${status.phoneNumber}`,
                                            value: status._id,
                                        };
                                    })}
                                    onChange={handleInputUser}
                                    placeholder="User"
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getUsers()
                                            : {}
                                    }
                                />
                            </div>

                            <div className="sameRowItem marginRight">
                                <Select
                                    options={vehiclesData.map((status, i) => {
                                        return {
                                            label: `${status.plate}`,
                                            value: status._id,
                                        };
                                    })}
                                    onChange={handleInputVehicle}
                                    placeholder="Vehicle"
                                    onClick={() =>
                                        vehiclesData[0] === undefined
                                            ? getVehicles()
                                            : {}
                                    }
                                />
                            </div>

                            <div className="sameRowItem marginRight">
                                {admin && !staff && (
                                    <Select
                                        options={limitOptions.map(
                                            (status, i) => {
                                                return {
                                                    label: `${status.label}`,
                                                    value: status.value,
                                                };
                                            }
                                        )}
                                        onChange={handleLimitChange}
                                        placeholder={"Format"}
                                    />
                                )}
                            </div>
                        </div>

<div className="samerow">
<div className="addProductItem ">
                            <input
                                className="inputFilter"
                                name="invoice"
                                value={invoice}
                                type="string"
                                placeholder="Invoice"
                                onChange={handleDelayedInvoiceChange}
                            />
                            
                        </div>

                        <div className="sameRowItem marginRight">
                                <Select
                                    options={statusOptions.map((status, i) => {
                                        return {
                                            label: status.label,
                                            value: status.value,
                                        };
                                    })}
                                    onChange={handleInputStatus}
                                    placeholder="Status"
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getAdmins()
                                            : {}
                                    }
                                />
                            </div>


</div>
                        

                        <div className="block">
                            {superadmin && (
                                <>
                                    <button
                                        onClick={() => {
                                            let payload = [];
                                            ordersData.payload.forEach(
                                                (order, i) => {
                                                    let productCodes =
                                                        order.products
                                                            .map(
                                                                (product) =>
                                                                    `${product.quantity} x ${product.code} (RM ${product.price})`
                                                            )
                                                            .join(", ");
                                                    payload.push({
                                                        No: i + 1,
                                                        Invoice:
                                                            order.invoiceId,
                                                        Customer:
                                                            order.userId.name,
                                                        PlateNumber:
                                                            order.plate,
                                                        Car:
                                                            order.car,
                                                        PhoneNumber:
                                                            order.userId.phoneNumber,
                                                        SKU: productCodes,
                                                        Retail: Number(order.amount),
                                                        Discount: Number(order.discount),
                                                        Sales: 
                                                        Number(order.amount -
                                                                order.discount -
                                                                (order.deposit
                                                                    ? order.deposit
                                                                    : 0))
                                                        ,
                                                        Deposit: 
                                                        Number(  order.deposit
                                                            ? order.deposit
                                                            : 0)
                                                        ,
                                                        PaymentType:
                                                            order.paymentType,
                                                        PurchaseDate: moment(
                                                            order.createdAt
                                                        ).format("YYYY-MM-DD"),
                                                    });
                                                }
                                            );
                                            excel({
                                                excelData: payload,
                                                fileName: `SSR-Orders-${moment(
                                                    inputs.dateFrom
                                                ).format(
                                                    "DD-MM-YYYY"
                                                )}-to-${moment(
                                                    inputs.dateTo
                                                ).format("DD-MM-YYYY")}`,
                                            });
                                        }}
                                        className="red buttonTop productListButton"
                                    >
                                        Export
                                    </button>

                                    <div className="reportContainer">
                                        <div className="featuredReport blue">
                                            <span className="featuredTitle">
                                                Total Orders:
                                            </span>
                                            <div className="featuredReportContainer">
                                                <span className="featuredMoney">
                                                    {" "}
                                                    {ordersData.totalPages}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="featuredReport red">
                                            <span className="featuredTitle">
                                                Total Amount:
                                            </span>
                                            <div className="featuredReportContainer">
                                                <span className="featuredMoney">
                                                    {" "}
                                                    RM{" "}
                                                    {formatter.format(
                                                        ordersData.totalAmount
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {inputs.status === "pending" &&
                                        inputs.user !== undefined && (
                                            <button
                                                onClick={() => {
                                                    const mergeItems = (
                                                        items
                                                    ) => {
                                                        // Initialize the merged result object
                                                        const merged = {
                                                            address: "-",
                                                            amount: 0,
                                                            discount: 0,
                                                            products: [],
                                                            invoiceIds: [],
                                                            cashier:
                                                                items[0]
                                                                    .cashier, // Assuming cashier is the same for all items
                                                            createdAt:
                                                                new Date().toISOString(),
                                                            createdBy:
                                                                items[0]
                                                                    .createdBy, // Assuming createdBy is the same for all items
                                                            paymentType:
                                                                items[0]
                                                                    .paymentType, // Assuming paymentType is the same for all items
                                                            salesType:
                                                                items[0]
                                                                    .salesType, // Assuming salesType is the same for all items
                                                            status: items[0]
                                                                .status, // Assuming status is the same for all items
                                                            updatedAt:
                                                                new Date().toISOString(),
                                                            userId: items[0]
                                                                .userId, // Assuming userId is the same for all items
                                                            __v: 0,
                                                            _id: `merged_${Date.now()}`, // Generating a new ID for the merged item
                                                        };

                                                        // Loop through each item and merge the fields
                                                        items.forEach(
                                                            ({
                                                                amount,
                                                                discount,
                                                                products,
                                                                createdAt,
                                                                invoiceId,
                                                                plate,
                                                            }) => {
                                                                merged.amount +=
                                                                    amount;
                                                                merged.discount +=
                                                                    discount;
                                                                products.forEach(
                                                                    (
                                                                        product
                                                                    ) => {
                                                                        product.productdate =
                                                                            moment(
                                                                                createdAt
                                                                            ).format(
                                                                                "YYYY-MM-DD"
                                                                            );
                                                                        product.invoiceId =
                                                                            invoiceId;
                                                                        product.plate =
                                                                            plate;
                                                                    }
                                                                );

                                                                merged.products =
                                                                    [
                                                                        ...merged.products,
                                                                        ...products,
                                                                    ];
                                                            }
                                                        );

                                                        return merged;
                                                    };

                                                    const mergedItem =
                                                        mergeItems(
                                                            ordersData.payload
                                                        );

                                                    createBulk(mergedItem);
                                                }}
                                                className="blue buttonTop productListButton"
                                            >
                                                Customer Statement
                                            </button>
                                        )}
                                </>
                            )}
                        </div>
                        {isLoaded === true && (
                            <Table
                                data={ordersData.payload}
                                columns={columns}
                                pageCount={
                                    ordersData
                                        ? Math.floor(
                                              ordersData.totalPages / 20
                                          ) + 2
                                        : 1
                                }
                                totalCount={ordersData.totalPages}
                                updatePagination={setInputs}
                                paginationState={inputs}
                                updateLoaded={setIsLoaded}
                            />
                        )}
                    </div>

                    {statusMenu && (
                        <div className="overlayContainer">
                            <div className="bigcenter">
                                <div
                                    className="close"
                                    onClick={() => setStatusMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="updatemodalContainer">
                                    <div className="updateOrder">
                                        <h1 className="updateProductTitle text-xl font-semibold">
                                            Products({modalData.length})
                                        </h1>
                                        <Table
                                            data={modalData}
                                            columns={productColumns}
                                            hidePagination={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {voidMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setVoidMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Confirm Void Order?
                                        </h3>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleVoid(modalData);
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {acceptMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setAcceptMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Confirm Accept Order?
                                        </h3>
                                        <div className="">
                                            <label className="flex justify-start text-lynch">
                                                Payment Type
                                            </label>
                                            <Select
                                                options={paymentType.map(
                                                    (paymentType, i) => {
                                                        return {
                                                            label: paymentType.label,
                                                            value: paymentType.value,
                                                        };
                                                    }
                                                )}
                                                onChange={handlePaymentType}
                                            />
                                        </div>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleAccept(modalData);
                                                    setJournalMenu(false);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setAcceptMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {installmentMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setInstallmentMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Add Installment
                                        </h3>
                                        <h3 className="updateProductTitle">
                                            Remaining Balance :{" "}
                                            {process.env.REACT_APP_COUNTRY ===
                                            "SINGAPORE"
                                                ? "$"
                                                : "RM"}{" "}
                                            {formatter.format(remainingBalance)}
                                        </h3>
                                        <div className="paymentTypeContainer">
                                            <div className="addProductItem">
                                                <label className="">
                                                    Amount (RM)
                                                </label>
                                                <input
                                                    name="amount"
                                                    type="number"
                                                    placeholder="Amount"
                                                    onChange={
                                                        handleChangeAmount
                                                    }
                                                />
                                            </div>
                                            <div className="addProductItem">
                                                <label className="">
                                                    Card No.
                                                </label>
                                                <input
                                                    name="card"
                                                    type="string"
                                                    placeholder="Card"
                                                    onChange={handleCard}
                                                />
                                            </div>
                                            <div className="addProductItem">
                                                <label>Payment Type</label>
                                                <select
                                                    name="paymentType"
                                                    id="paymentType"
                                                    onChange={handleChange}
                                                >
                                                    <option value="cash">
                                                        Cash
                                                    </option>
                                                    <option value="card">
                                                        Debit / Credit Card
                                                    </option>
                                                    <option value="onlineTransfer">
                                                        Online Transfer
                                                    </option>
                                                    <option value="tng">
                                                        Touch N Go
                                                    </option>
                                                    <option value="cheque">
                                                        Cheque
                                                    </option>
                                                    <option value="others">
                                                        Others
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="addProductItem">
                                                <label>Date</label>
                                                <input
                                                    type="date"
                                                    onChange={handleDate}
                                                    placeholder="Select a date"
                                                />
                                            </div>
                                        </div>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleInstallment(
                                                        modalData
                                                    );
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setInstallmentMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {journalMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setJournalMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Journal
                                        </h3>
                                        <div className="paymentTypeContainer">
                                            <div className="addProductItem">
                                                <label className="">
                                                    Amount (RM)
                                                </label>
                                                <input
                                                    name="amount"
                                                    type="number"
                                                    placeholder="Amount"
                                                    onChange={
                                                        handleChangeAmount
                                                    }
                                                />
                                            </div>
                                            <div className="addProductItem">
                                                <label className="">
                                                    Details
                                                </label>
                                                <input
                                                    name="card"
                                                    type="string"
                                                    placeholder="Details"
                                                    onChange={handleCard}
                                                />
                                            </div>
                                            <div className="addProductItem">
                                                <label>Account Name</label>
                                                <select
                                                    name="paymentType"
                                                    id="paymentType"
                                                    onChange={handleAccountType}
                                                >
                                                    <option value="pbb">
                                                        Public Bank Berhad
                                                    </option>
                                                    <option value="cardcomm">
                                                        Credit Card - Merchant
                                                        Commission
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleJournal(modalData);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setJournalMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
